import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import "./index.css";
import { ToastContainer } from "react-toastify";
import LoadingComponent from "../../components/loader/LoadingComponent";
import Header from "../../components/header/header";
import { useDispatch } from "react-redux";
import { logOut } from "../../redux/actions/userAction";
import { BASE_URL, encryptData, S3_BASE_URL, GOOGLE_RECAPTCHA_SITE_KEY, failureAlert, IsAdmin, axiosApiInstance, videoUrl } from "../../helpers/helper";
import Footer from "../../components/footer/footer";
import iconcompetition from "../../assets/icons/iconcompetition.png";
import q from "../../assets/icons/quater.jpg";
import { Link, useHistory } from "react-router-dom";
import exploreMoreIcon from "../../assets/icons/exploreMoreIcon.png";
import Tabs from "../../components/AccordionTab";
import Accordion from "../../containers/Accordion";
import img1 from '../../assets/icons/image1.png';
import img2 from '../../assets/icons/image2.png';
import img3 from '../../assets/icons/imgab.png';
import img4 from '../../assets/icons/Image-1.png';
import ee from '../../assets/icons/ee.png';
import dei from '../../assets/icons/dei.png';
import rightarr from '../../assets/icons/rightarr.svg';
import { getAllQuartersInfo, addQuartersImages, updateQuarterData, addOccasionGamesQuartersData, getAllCalendarData, updateOccasionGamesInQuarter } from "../../redux/actions/calendarActions";
import { useSelector } from "react-redux";
import domtoimage from 'dom-to-image';
import { Helmet } from "react-helmet-async";
import ContactUsModal from "../modal/contactUsModal";
import IndividualGameModal from "../modal/individualGameModal";
import DemoRequestModal from "../../components/modal/demorequestmodal";
import { fetchOccasionGames } from "../../redux/actions/calendarActions";
import WebinarDescriptionModal from "../../components/modal/webinardescriptionmodal";
const Calendar = (props) => {
  const history = useHistory();
  const [selectedValue, setSelectedValue] = useState('Employee Engagement');
  const UserInfo = useSelector((state) => state.getUser);

  const { userInfo } = UserInfo;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const sectionRef = useRef(null);
  const sectionRefMobile = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };


  const handleDownloadDesktop = () => {
    setLoaded(false);
   

    if (sectionRef.current && imageLoaded) {
      setDownload(true);
      html2canvas(sectionRef.current, {
        useCORS: true,
        allowTaint: false,
        backgroundColor: null,
        logging: true,
        scale: 2,
        x: 0,
        y: 0
      }).then((canvas) => {
        const imageUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'calendar.png';
        link.click();
        setLoaded(true);
        setDownload(false);
      });
    } else {
      console.error("Section not found or image not loaded");
      setLoaded(true);
      setDownload(false);
    }
  };

const [webinarDetails, setWeinarDetails] = useState(null);
  const [playClicked, setPlayClicked] = useState("");
  const clickFunction = (webinar) => {
    setWeinarDetails(webinar);
    setOpenWebinarDescriptionModal(true);
  }
  const getMoreWebinars = () => {
    return [];
   
  }


  const handleDownloadMobile = () => {
    setLoaded(false);
    if (sectionRefMobile.current && imageLoaded) {
      setDownload(true);
      html2canvas(sectionRefMobile.current, {
        useCORS: true,
        allowTaint: true,
        backgroundColor: "white",
        logging: true,
        scale: 2,
        x: 0,
        y: 0
      }).then((canvas) => {
        const imageUrl = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imageUrl;
        link.download = "calendar.png";
        link.click();
        setLoaded(true);
        setDownload(false);
      }).catch((error) => {
        console.error("Error capturing the image:", error);
        setLoaded(true);
        setDownload(false);
      });
    } else {
      console.error("Section not found or image not loaded");
      setLoaded(true);
    }
  };
  const [focusedIdx, setFocusedIdx] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const cardsPerPage = 12;
  const tabs1 = [
    { id: 0, label: "Play" },
    { id: 1, label: "Games" },
    { id: 2, label: "Live Events" }
  ];
  const playFaq = [
    { title: 'What is ExtraMile Play?', content: "ExtraMile Play caters to every kind of team—whether remote, offline, online, big, small, or somewhere in between, we've got the perfect adventure waiting for you!" },
    { title: 'How do I sign up for ExtraMile Play?', content: 'Signing up is as easy as a high-five! Just visit our website, choose your plan, and dive into a world where team spirit meets limitless fun.' },
    {
      title: "Can I play a game without subscribing to the platform?",
      content: "Yes, you can! Feel free to try out any game and play up to stage 1 to experience the fun and excitement firsthand before deciding to subscribe. It's the perfect way to get a feel for what ExtraMile Play has to offer!"
    },
    {
      title: " What subscription plans does ExtraMile Play offer?",
      content: "We offer flexible plans to suit every team's needs—whether you prefer monthly adventures or want to save with annual subscriptions, we've got options that fit your style of working!"
    }, {
      title: "Are there any discounts for annual subscriptions?",
      content: "Yes! Enjoy exclusive discounts when you opt for our annual subscription plans, making team-building with ExtraMile Play both rewarding and cost-effective."
    }
  ];

  const gamesFaq = [
    { title: 'What types of games are available on ExtraMile Play?', content: 'From brain-teasing puzzles to creative challenges and everything in between, ExtraMile Play offers a diverse range of games designed to spark joy and foster teamwork.' },
    { title: "Can we customise the games to fit our company's theme?", content: "Absolutely! Personalise your team's experience by customising games to reflect your company's culture, values, and goals—making each activity uniquely yours. The options are available here. " },
    { title: "How many players can participate in a game?", content: "Whether your team is small and intimate or large and dynamic, our games are crafted to accommodate all group sizes, ensuring everyone can join in on the fun! Know more about it here. " },
    { title: "Are the games suitable for remote teams?", content: "Yes! ExtraMile Play is perfect for remote teams, offering engaging activities that bridge distances and foster collaboration, no matter where your team members are located." },
  ];
  const liveEventsFaq = [
    { title: "Can I use the games to do LIVE events on Zoom, MS Teams etc?", content: "A 100% yes! Playing ExtraMile Play's games for LIVE events on Zoom, MS Teams, or any virtual platform is extremely easy. We believe in creating engaging experiences that bring teams together no matter where they are, ensuring every event is a hit!" },
    { title: "Are there facilitators available to help run the LIVE events?   ", content: "Yes! Our expert facilitators ensure your LIVE events run smoothly, guiding your team through engaging activities that enhance teamwork. When confirming the event, we share profiles of our experts for you to choose from, providing the perfect fit to help run your event." },
  ];
  let accordionItems;
  switch (focusedIdx) {
    case 1:
      accordionItems = gamesFaq;
      break;
    case 2:
      accordionItems = liveEventsFaq;
      break;
    default:
      accordionItems = playFaq;
  }
  const [loaded, setLoaded] = useState(true);
  const [isdownloading, setDownload] = useState(false);
  const isAdmin = IsAdmin();
  const dispatch = useDispatch();
  const signOut = async () => {
    await dispatch(logOut());
    if (isAdmin) props.history.push("/admin");
    else props.history.push("/");
  };
  const allQuarterInfoData = useSelector((state) => state.allQuartersInfo);
  const calendarData = useSelector((state) => state.calendarData);
  const [calendarDataUploaded, SetAllCalendarData] = useState([]);
  const game = useSelector((state) => state.occasionGames);
  const [openGameModal1, setOpenGameModal1] = useState(false);
  const [openGameModal, setOpenGameModal] = useState(false);
  const [individualGame, setIndividualGameInfo] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openWebinarDescriptionModal, setOpenWebinarDescriptionModal] = useState(false);
  useEffect(() => {

    if (game && openGameModal) {
      setOpenModal(true);
      setIndividualGameInfo(game?.occasionGames?.data?.games[0]);
    }
    if(game && openGameModal1 && game?.occasionGames?.data?.games[0]){
     
       setWeinarDetails(game?.occasionGames?.data?.games[0]);
       setOpenWebinarDescriptionModal(true);
    }
  }, [game, openGameModal,openGameModal1])



  useEffect(() => {

    dispatch(getAllQuartersInfo(selectedValue));
    dispatch(getAllCalendarData(selectedValue));
  }, [selectedValue])

  useEffect(() => {

    if (calendarData && allQuarterInfoData) {

      const calendardata = calendarData?.allCalendarData?.data;
    
      SetAllCalendarData(calendardata)
    }
  }, [calendarData, allQuarterInfoData]);

  useEffect(() => {
    if (
      (allQuarterInfoData && allQuarterInfoData.loading) || (calendarData && calendarData.loading)
    )
      setLoaded(false);
    else
      setLoaded(true);
  }, [allQuarterInfoData, calendarData]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    return `${month} ${day}`;
  }

  const [openContactUsModal, setOpenContactUsModal] = useState(false);
  const [openDemoRequestModal, setOpenDemoRequestModal] = useState(false);

  const [hasContactUsModalClosed, setHasContactUsModalClosed] = useState(false);
  const [hasSingleGameModalClosed, setSingeGameModalClosed] = useState(false);
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {

    if (hasContactUsModalClosed) {
      setOpenDemoRequestModal(true);
    }
  }, [hasContactUsModalClosed])

  const handleTabClick = (type) => {

    setSelectedValue(type);
  };
  const getTabClass = (tab) => {
    return selectedValue === tab
      ? 'bg-black text-white shadow'
      : 'bg-white text-black shadow';
  };



  const [imageUrls, setImageUrls] = useState({});


  const fetchImage = async (path) => {
    try {
      const response = await axiosApiInstance.post(
        BASE_URL + "/api/Calendar/download",
        { filepath: path },
        { responseType: "blob" }
      );
      const objectUrl = URL.createObjectURL(response.data);
      return objectUrl;
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  };

  useEffect(() => {
    const loadImages = async () => {

      const newImageUrls = {};
      for (const quarter of calendarDataUploaded) {

        const imageUrl = await fetchImage(quarter.path);
        const mobileimageUrl = await fetchImage(quarter.mobilePath);
        newImageUrls[quarter.path] = imageUrl;
        newImageUrls[quarter.mobilePath] = mobileimageUrl;
      }
      setImageUrls(newImageUrls);
    };

    if (calendarDataUploaded?.length > 0) {
      loadImages();
    }


    return () => {
      Object.values(imageUrls).forEach((url) => URL.revokeObjectURL(url));
    };
  }, [calendarDataUploaded]);


  

  const fetchGameDataAndOpenModal = (occasionId,isWebinar) => {

    dispatch(fetchOccasionGames(occasionId, searchTerm));
    if(isWebinar){
  setOpenGameModal1(true)
      setOpenGameModal(false)
    }
   else{
    setOpenGameModal1(false)
    setOpenGameModal(true)
   }
  }

  const handleButtonClick = () => {
    if (userInfo) {
      setOpenContactUsModal(true);
    } else {

      history.push({
        pathname: "/",
        state: { fromCalendar: true },
      });
    }
  };


  return (
    <>
         {
        openWebinarDescriptionModal &&
        <WebinarDescriptionModal
          modalid={"webinar-description"}
          setOpenWebinarDescriptionModal={setOpenWebinarDescriptionModal}
          toggle={openWebinarDescriptionModal}
          key={"webinar-description"}
          moreWebinars={getMoreWebinars()}
          webinar={webinarDetails}
          setPlayClicked={setPlayClicked}
          clickFunction={clickFunction}
          // activeCategoryName={activeCategoryName}
        />
      }
      {
        openDemoRequestModal &&
        <DemoRequestModal modalid={"demo-request-modal"} toggle={openDemoRequestModal} setOpenDemoRequestModal={setOpenDemoRequestModal} />
      }
      {openModal && (
        <IndividualGameModal
          modalid="singleGameModal"
          toggle={openModal}
          setOpenModal={setOpenModal}
          setSingeGameModalClosed={setSingeGameModalClosed}
          game={individualGame}
        />
      )}
      {openContactUsModal && (
        <ContactUsModal
          modalid="contactUsModal"
          toggle={openContactUsModal}
          setOpenContactUsModal={setOpenContactUsModal}
          userInfo={userInfo}
          setHasContactUsModalClosed={setHasContactUsModalClosed}
        />
      )}
      <Helmet>
        <title>{selectedValue} Calendar</title>
        <meta name="description" content={selectedValue + '' + 'Calendar'} />
        <meta key="keywords" content={selectedValue + '' + 'Calendar'} />
      </Helmet>
      <ToastContainer position="bottom-center" />
      <LoadingComponent loaded={loaded} />
      <Header
        profile
        {...props}
        signOut={signOut}
        isAdmin={isAdmin}
      />
      <section className="flex flex-col items-center py-0 lg:py-10 pt-[72px]">
        <div className="flex lg:flex-row flex-col items-center gap-8 mt-8 lg:mt-24 mb-4 lg:mb-8">
          <div class="sm:block hidden circle-shadow"></div>
          <div
            onClick={() => handleTabClick("Employee Engagement")}

            className={`relative p-4 cursor-pointer rounded-md w-64 mx-auto transition-all duration-300 hover:bg-black hover:text-white group ${getTabClass("Employee Engagement")}`}
          >
            <img
              src={ee}
              alt="Top Right Image"
              className="top-0 right-8 absolute w-12 h-12 transform transition-transform -translate-y-1/2 translate-x-1/2 group-hover:-translate-y-10 duration-300 object-cover"
            />
            <h2 className="mt-0 text-center text-lg">Employee Engagement</h2>
          </div>



          <div class="sm:block hidden circle-shadow-yellow"></div>
          <div
            onClick={() => handleTabClick("DEI")}

            className={`relative p-4 cursor-pointer rounded-md w-64 mx-auto transition-all duration-300 hover:bg-black hover:text-white group ${getTabClass("DEI")}`}
          >
            <img
              src={dei}
              alt="Top Right Image"
              className="top-0 right-8 absolute w-12 h-12 transform transition-transform -translate-y-1/2 translate-x-1/2 group-hover:-translate-y-10 duration-300 object-cover"
            />
            <h2 className="mt-0 text-center text-lg">DEI</h2>
          </div>

        </div>



        <h1 className="mt-6 lg:mt-12 mb-4 font-bold text-center text-xl sm:text-xl md:text-3xl lg:text-3xl">Employee Engagement <span className='highlight'>Calendar</span></h1>


        <p className="mb-6 text-center calenderPara">
          Experiences that engage, excite and bond teams together
        </p>

        <div className="flex sm:flex-row flex-col justify-center">
          {/* <Link to={{
            pathname: "/",
            state: { fromCalendar: true }
          }} className="flex justify-center items-center bg-white hover:bg-red-500 mr-4 mb-4 sm:mb-0 px-4 py-2 rounded-md w-60 hover:text-white calendarBorder">

            Get In Touch
          </Link> */}
          <button
            onClick={handleButtonClick}
            className="flex justify-center items-center hover:opacity-80 mr-4 mb-4 sm:mb-0 px-4 py-2 rounded-md w-60 calendarBorder"
          >
            Get In Touch
          </button>


          {/* <button
            onClick={handleDownloadDesktop}
            class="desktop-btn-down hover:bg-red-600 px-4 py-2 rounded-md w-60 btn btn-primary"
          >
            Download Calendar
          </button>


          <button
            onClick={handleDownloadMobile}
            class="hover:bg-red-600 px-4 py-2 rounded-md w-60 btn btn-primary mobile-btn-down"
          >
            Download Calendar
          </button> */}


        </div>


      </section>


      <div className='gradientYellow'>
        <section className="lg:block relative hidden px-4 py-0 lg:py-8" ref={sectionRef}>

          {!loaded && !isdownloading ? (
          
            [...Array(3)].map((_, index) => (
              <div key={index} className={`w-[95%] mx-auto overflow-hidden mb-8 OcasionContainerCard  `}>
                <div className="relative z-10 gap-4 grid grid-cols-6 bg-slate-100 p-6 h-[329px] skeleton-animation"></div>
              </div>
            ))
  
          ) : (

            calendarDataUploaded && calendarDataUploaded.map((quarter, quarterIndex) => (
              <>
                <div class="sm:block hidden circle-shadow1"></div>
                <div class="sm:block hidden circle-shadowBlue"></div>
                <div key={quarterIndex} className={`w-[95%] mx-auto overflow-hidden mb-8 OcasionContainerCard  `}>
                  {
                    !loaded ? (
                      <div className="relative z-10 gap-4 grid grid-cols-6 bg-slate-100 p-6 h-[329px] skeleton-animation"></div>

                    ) : (
                      <div className="relative z-10 gap-4 grid grid-cols-6 p-6">

                        <div className="relative flex flex-col items-center col-span-6 sm:col-span-1 mb-4 sm:mb-0 rounded-xl overflow-visible">
                          <div className="relative w-full h-[300px]">
                            <div>
                              {imageUrls[quarter.path] ? (
                                <img
                                  src={imageUrls[quarter.path]}
                                  className="absolute mt-1 rounded-xl w-full h-full object-cover"
                                  onLoad={handleImageLoad}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="relative z-10 p-4">
                              <h4 className="relative z-10 mb-0" style={{ fontSize: '50px', fontWeight: '900', color: quarter.color }}>
                                {quarter.quarter}
                              </h4>
                              <p
                                className="mb-1 border-b-2 w-max font-bold"
                                style={{ paddingBottom: '8px', color: quarter.color, borderBottomColor: quarter.color }}
                              >
                                Coverage
                              </p>
                              <div className="relative z-10 bg-opacity-50 w-full">
                                <p className="mb-3 pt-1 font-bold" style={{ color: quarter.color }}>
                                  {quarter.occasionCount} Occasions
                                </p>
                                <p className="mb-3 font-bold" style={{ color: quarter.color }}>
                                  {quarter.gameCount} Experiences
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="gap-4 grid grid-cols-1 sm:grid-cols-3 col-span-6 sm:col-span-5">
                          {quarter.months.map((month, monthIndex) => (
                            <div key={monthIndex} className={`flex flex-col items-start p-2 sm:pr-6 border-b sm:border-b-0 ${monthIndex !== 2 ? 'sm:border-r-2' : ''}`}>
                              <h4 className="mb-4 calendarTitle">{month.month}</h4>

                              <ul className={`list-none space-y-4 w-full h-auto`}>
                                {month.occasions.length === 0 ? (
                                  <li className="text-gray-500">No Occasions Added</li>
                                ) : (
                                  month.occasions
                                    .sort((a, b) => new Date(a.date) - new Date(b.date)) 
                                    .map((occasion, occasionIndex) => (
                                      <li key={occasionIndex} className="flex justify-between items-center">
                                        <div className="flex-grow">
                                          <div className="CalendarEventName">
                                            {occasion.date === occasion.toDate
                                              ? formatDate(occasion.date)
                                              : `${formatDate(occasion.date)} - ${formatDate(occasion.toDate)}`}
                                          </div>


                                          <div className="flex justify-between items-center cursor-pointer"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (occasion.games.length === 1 ) {
                                                fetchGameDataAndOpenModal(occasion.id,occasion.games[0].isWebinar);
                                              } 
                                               else {
                                                history.push(`/occasion-games/${occasion.id}`);
                                              }
                                            }}
                                          >
                                           
                                            <span className="font-bold">{occasion.occasion}</span>

                                            <span className="mr-4 ml-2 text-gray-500 text-xl">
                                            
                                              <img src={rightarr} alt="" className="w-3" />

                                            </span>
                                          </div>

                                        </div>
                                      </li>
                                    ))
                                )}
                              </ul>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  }


                </div>
              </>
            ))
          )}
        </section>
      </div>

      <section class="block sm:hidden px-0 py-8 gradient1" ref={sectionRefMobile}>

        {
          !loaded && !isdownloading ? (
            [...Array(3)].map((_, index) => (
              <div key={index} className={`mx-auto mb-8 w-[90%] overflow-hidden OcasionContainerCard `}>
                <div className="relative z-10 gap-4 grid grid-cols-6 bg-slate-100 p-6 h-[400px] skeleton-animation"></div>
              </div>
            ))
          ) : calendarDataUploaded && calendarDataUploaded.map((quarter, quarterIndex) => (
            <div key={quarterIndex} className="mx-auto mb-8 w-[90%] overflow-hidden OcasionContainerCard" style={{ height: 'auto' }}>
              <div className="gap-0 grid grid-cols-6 p-3">
                <div className="relative flex flex-col items-center col-span-6 sm:col-span-1 mb-0 sm:mb-0 rounded-xl">
                  <div className="relative w-full h-full" >

                    <div>
                      {imageUrls[quarter.mobilePath] ? (
                        <img
                          src={imageUrls[quarter.mobilePath]}

                          className="absolute mt-1 rounded-xl w-full h-full"
                          onLoad={handleImageLoad}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="relative z-10 p-4">
                      <h4
                        className="relative z-10 mb-0"
                        style={{ fontSize: '50px', fontWeight: '900', color: quarter.color }}
                      >
                        {quarter.quarter}
                      </h4>


                    </div>
                  </div>
                </div>



                <div className="gap-4 grid grid-cols-1 sm:grid-cols-3 col-span-6 sm:col-span-5">
                  {quarter.months.map((month, monthIndex) => (
                    <div
                      key={monthIndex}
                      className={`flex flex-col items-start p-4 sm:pr-6 sm:border-b-0 ${monthIndex !== 2 ? 'border-b-2' : ''}`}
                    >
                      <h4 className="mb-4 calendarTitle">{month.month}</h4>

                      <ul className={`list-none space-y-4 w-full scrollable-container-mob`}>
                        {month.occasions.length === 0 ? (
                          <li className="text-gray-500">No Occasions Added</li>
                        ) : (
                          month.occasions.map((occasion, occasionIndex) => (
                            <li key={occasionIndex} className="flex justify-between items-center">
                              <div className="flex-grow">

                                <div className="CalendarEventName">
                                  {occasion.date === occasion.toDate
                                    ? formatDate(occasion.date)
                                    : `${formatDate(occasion.date)} - ${formatDate(occasion.toDate)}`}
                                </div>

                                <div className="flex justify-between items-center cursor-pointer"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (occasion.games.length === 1) {
                                      fetchGameDataAndOpenModal(occasion.id,occasion.games[0].isWebinar);
                                    } else {
                                      history.push(`/occasion-games/${occasion.id}`);
                                    }
                                  }}
                                >
                            
                                  <span className="font-bold">{occasion.occasion}</span>

                                  <span className="mr-4 ml-2 text-gray-500 text-xl">
                               
                                    <img src={rightarr} alt="" className="w-3" />

                                  </span>
                                </div>
                              </div>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                  ))}
                </div>

              </div>
            </div>
          ))

        }
    
      </section >
      <section className="section section6" >
        <div className="desktopView left-section">
          <div className="relative parent">

            <img className="image1" src={img1} alt="Top Right Image" />
            <img className="image2" src={img3} alt="Bottom Left Image" />
          </div>
        </div>
        <div class="relative sm:hidden mt-[1rem] mb-[3rem]">
          <div class="relative top-0 right-[-20%] z-20">
            <img src={img1} alt="Bottom Image" class="w-[80%] h-auto" />
          </div>
          <div className="bottom-[-67px] left-[-28px] z-0 absolute">
            <img src={img4} alt="Top Image" className="w-[60%] h-auto" />
          </div>

        </div>
        <div className="desktopView right-section !pr-6">
          <h2>
            Year Round Activities To Boost<br /><span className='highlight'>Employee Engagement</span>
          </h2>


          <p> Experience the perfect activities to engage, excite, and strengthen your team, available for every occasion throughout the year.</p>
          <div className="games-grid-items-container gap-4 grid grid-cols-2">
            <div className="p-4 calendarSubCard">
              <h6 className="mb-2 calendarSubTitle">Flexibility</h6>
              <p className="calenderPara">Our platform adapts seamlessly to remote, or hybrid workplaces, ensuring
                everyone stays connected and engaged - irrespective of the team size</p>
            </div>

            <div className="p-4 calendarSubCard">
              <h6 className="mb-2 calendarSubTitle">Highly Engaging</h6>
              <p className="calenderPara">From brain-teasing puzzles to pulse-pounding challenges, each experience boosts
                communication and strengthens team spirit</p>
            </div>
            <div className="p-4 calendarSubCard">
              <h6 className="mb-2 calendarSubTitle">Tailored experiences</h6>
              <p className="calenderPara">Customise experiences to fit your needs and objectives with your preferred content in our games and wrappers</p>
            </div>
            <div className="p-4 calendarSubCard">
              <h6 className="mb-2 calendarSubTitle">Seamless integration</h6>
              <p className="calenderPara">Our games seamlessly connect with your favourite tools, such as Slack, Microsoft
                Teams, and your HRMS or LMS –making teamwork smoother and more efficient than
                ever</p>
            </div>

          </div>


        </div>
        <div className="block right-section sm:hidden mobileView">
          <h2>
            Year Round Activities To Boost<br /><span className='highlight'>Employee Engagement</span>
          </h2>

          <p> Experience the perfect activities to engage, excite, and strengthen your team, available for every occasion throughout the year.</p>
          <div className="flex flex-col games-grid-items-container space-y-4">
            <div className="flex flex-col bg-white shadow-md p-5 rounded-lg w-full max-w-sm">
              <span className="mb-2 font-semibold text-xl card-title">Flexibility</span>
              <p className="text-center text-gray-500 card-description">
                Our platform adapts seamlessly to remote, or hybrid workplaces, ensuring
                everyone stays connected and engaged - irrespective of the team size
              </p>
            </div>

            <div className="flex flex-col bg-white shadow-md p-5 rounded-lg w-full max-w-sm">
              <span className="mb-2 font-semibold text-xl card-title">Highly Engaging</span>
              <p className="text-center text-gray-500 card-description">
                From brain-teasing puzzles to pulse-pounding challenges, each experience boosts
                communication and strengthens team spirit
              </p>
            </div>

            <div className="flex flex-col bg-white shadow-md p-5 rounded-lg w-full max-w-sm">
              <span className="mb-2 font-semibold text-xl card-title">Tailored experiences</span>
              <p className="text-center text-gray-500 card-description">
                Customise experiences to fit your needs and objectives with your preferred content in our games and wrappers
              </p>
            </div>
            <div className="flex flex-col bg-white shadow-md p-5 rounded-lg w-full max-w-sm">
              <span className="mb-2 font-semibold text-xl card-title">Seamless integration</span>
              <p className="text-center text-gray-500 card-description">
                Our games seamlessly connect with your favourite tools, such as Slack, Microsoft
                Teams, and your HRMS or LMS –making teamwork smoother and more efficient than
                ever
              </p>
            </div>

          </div>


        </div>
      </section>

      <section className="relative section10" >
        <div class="sm:block hidden circle-shadow2"></div>
        <div class="sm:block hidden circle-shadow3"></div>
        <div class="sm:block hidden circle-shadow4"></div>
        <div className="!pr-1 !pl-1 games-accordion-bookevent">

          <div className="title-wrapper">
            <div className="section-wrapper">
              <div className="title-wrapper1">
                <h2>
                  Frequently Asked<span className="highlight"> Questions </span>
                </h2>
                <p className="description">
                  Got questions? Check out our FAQs for all the info you may need or write to us if
                  we don’t answer them all.
                </p>
              </div>
              <div className="explore-more">
                <Link to={`/faq`} className="black btn">
                  <span style={{ marginRight: '10px' }}>  Got Questions</span>
                  <img src={exploreMoreIcon} alt="Icon" class="btn-icon" />
                </Link>
              </div>
            </div>
          </div>
          <div className="image-wrapper">
            <div className="button-group">
              <div className="buttongrp" style={{ marginBottom: '1rem', width: '100%' }}>
                <Tabs value={focusedIdx} onChange={setFocusedIdx} tabs={tabs1} />
              </div>
              <div className="accordion"><Accordion items={accordionItems} page="bookEvent" /></div>
            </div>
          </div>
        </div>
      </section>

      <div className="relative z-10">
        <Footer {...props} isAdmin={isAdmin} />
      </div>
    </>
  );

};

export default Calendar;