import React, { useEffect, useState, useRef } from "react";

import PageLayout from '../../components/pagelayout/pagelayout';
import ScheduledGames from '../../components/scheduledGames/scheduledGames';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import MyFavourites from "../../components/myFavourites/myFavourites";
import { sidebarContentScheduledByOthers, sideBarContentMyFavourites, sidebarContentScheduledByMe } from "../../helpers/sidebarContentMyGames";
import { logOut } from "../../redux/actions/userAction";
import Roles from '../../helpers/userTypes';
import Reports from "../reports/reports";
import PollDetailsTable from "../../components/pollDetailsTable/pollDetailsTable";
import DefaultGames from '../../components/defaultgames/DefaultGames';
import Header from "../../components/header/header";
import img1 from '../../assets/icons/image1.png';
import LoadingComponent from "../../components/loader/LoadingComponent";
import WebinarDescriptionModal from "../../components/modal/webinardescriptionmodal";
import WebinarPlayCard from "../../components/playCard/webinarPlayCard";
import arrowleft from '../../assets/images/paginationarrow.svg';
import lohri from '../../assets/images/LohriBanner2.png';
import search from '../../assets/images/search.svg';
import Playcardlarge from '../../components/playCard/playcardlarge';
import iconfilter from "../../assets/icons/iconfilter.png";
import { fetchOccasionGames } from "../../redux/actions/calendarActions";
import LikeShareModal from '../../components/modal/likesharemodal';
import PlayCard from "../../components/playCard/playCard_new";
import Footer from "../../components/footer/footer";
import CalendarGamesFilterModal from "../../components/modal/calendarGamesFilterModal";
import { gameAllCategory } from "../../redux/actions/gameDetailAction";
import virtual from '../../assets/icons/iconvirtual.png';
import time from '../../assets/icons/icon_time.png';
import participant from '../../assets/icons/icon_participants.png';
import { Link } from 'react-router-dom';
import "./ocassionGames.css";
import img3 from '../../assets/icons/28states.jpg';
import { S3_BASE_URL, axiosApiInstance, BASE_URL, decryptData, encryptData, GetUserType, IsAdmin, REG_EX_URL_FORMAT, successAlert, failureAlert } from "../../helpers/helper";
const OccasionGames = (props) => {

  const allQuarterInfoData = useSelector((state) => state.occasionGames);
  const { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [allGames, setAllGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const dispatch = useDispatch();
  const isAdmin = IsAdmin();
  const signOut = async () => {
    await dispatch(logOut());
    props.history.push("/");
  };
  const [page, setPage] = useState(1);
  const changePage = (newPage) => {

  }

  const debounceTimeoutRef = useRef(null);
  const [mobile, setMobileBanner] = useState(null);
  const [desktop, setDesktopBanner] = useState(null);
  const [openMobileHoverCard, setOpenMobileHoverCard] = useState(false);
  const [clearFilter, setClearFiltertrue] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [gameDetails, setGameDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState("")

  const [openWebinarDescriptionModal, setOpenWebinarDescriptionModal] = useState(false);
  const { gameCategory } = useSelector((state) => state.gameAllCategory);
  const storedFormData = useSelector((state) => state.calendarfilterFormData);



  useEffect(() => {
    dispatch(gameAllCategory());
  }, [])
  useEffect(() => {
    if (searchTerm) {
      setLoaded(false);
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
      debounceTimeoutRef.current = setTimeout(() => {
        setCurrentPage(1);
        dispatch(fetchOccasionGames(id, searchTerm));
      }, 1500);
    } else {
      setLoaded(false);
      dispatch(fetchOccasionGames(id, searchTerm));
    }

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchTerm, id, dispatch]);


  useEffect(() => {
    if (allQuarterInfoData) {
      const alldata = allQuarterInfoData?.occasionGames?.data?.games;
      const desktopBaner = allQuarterInfoData?.occasionGames?.data?.desktopBanner;
      const mobileBanner = allQuarterInfoData?.occasionGames?.data?.mobileBanner;

      setDesktopBanner(desktopBaner);
      setMobileBanner(mobileBanner);

      setAllGames(alldata);
      if (allQuarterInfoData.loading) {
        setLoaded(false);
      } else {
        setLoaded(true);
      }
    }
  }, [allQuarterInfoData]);
  const cardsPerPage = 12;


  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);


  const totalPages = Math.ceil(allGames?.length / cardsPerPage);

  const goToPage = (page) => {
    if (page < 1) {
      setCurrentPage(1);
    } else if (page > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(page);
    }
  };
  const getObjectKeysCountAndValues = (obj) => {

    if (!obj || Object.keys(obj).length === 0) {
      return null;
    }

    const result = Object.entries(obj).reduce((acc, [key, value]) => {
      if (key === 'id') {
        return acc;
      }
      if (value !== undefined && value !== null && value !== '' && !(Array.isArray(value) && value.length === 0)) {
        acc.push({
          key,
          value: Array.isArray(value) ? value.length : value,
        });
      }
      return acc;
    }, []);


    return {
      count: result.length,
      keysWithValues: result,
    };
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };



  const [expandedCardIndex, setExpandedCardIndex] = useState(null);

  const toggleDescription = (index) => {
    setExpandedCardIndex(expandedCardIndex === index ? null : index);
  };
  const formatGameType = (gameType) => {
    if (gameType === "ONLINE") {
      return "Online";
    }
    if (gameType === "ONSITE") {
      return "Onsite";
    }
    if (gameType === "HYBRID") {
      return "Hybrid";
    }
    return gameType;
  };
  const clickFunction = (webinar) => {
    setWeinarDetails(webinar);
    setOpenWebinarDescriptionModal(true);
  }
  const [webinarDetails, setWeinarDetails] = useState(null);
  const openWebinarModal = (webinar) => {
    setWeinarDetails(webinar);
    setOpenWebinarDescriptionModal(true);
  };
  const getMoreWebinars = () => {
    return [];

  }
  const [playClicked, setPlayClicked] = useState("");
  return (
    <div >
      {
        openWebinarDescriptionModal &&
        <WebinarDescriptionModal
          modalid={"webinar-description"}
          setOpenWebinarDescriptionModal={setOpenWebinarDescriptionModal}
          toggle={openWebinarDescriptionModal}
          key={"webinar-description"}
          moreWebinars={getMoreWebinars()}
          webinar={webinarDetails}
          setPlayClicked={setPlayClicked}
          clickFunction={clickFunction}
        // activeCategoryName={activeCategoryName}
        />
      }

      <Header profile {...props} signOut={signOut} {...props} isAdmin={isAdmin} />
      <div className="mt-[65px] md:mt-[72px] p-0 w-full h-auto">
        <div className="section section1" id="windows-section">
          <div className="image-container">


            <div className="flex justify-center items-center m-0 carousel-games-item">
              <img
                src={S3_BASE_URL + desktop}
                className="desktopView w-full h-full object-cover"
                alt={`Banner `}
              />

              <img
                src={S3_BASE_URL + mobile}
                alt={`Logo Mobile`}
                className="w-full h-full mobileView object-contain"
                style={{
                  height: 'calc(100vh - 72px)',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />

            </div>
          </div>
        </div>

        <LoadingComponent loaded={loaded} />
        <div className="mx-[20px] !p-4">
          <div class="sm:block z-0 hidden circle-shadow-top"></div>

          <div className="gap-6 grid grid-cols-1 lg:grid-cols-2">
            {allGames &&
              allGames.map((game, index) => (
                <div
                  key={index}
                  className="z-10 flex lg:flex-row flex-col items-start lg:space-x-6 space-y-4 lg:space-y-0 p-4 lg:p-2 singleGameItem"
                >
                  {
                    game.isWebinar
                  }
                  <div className="flex-shrink-0 m-0 sm:m-3 gameCard">

                    <img
                      src={game.isWebinar ? S3_BASE_URL + game?.mobileCoverMedia : S3_BASE_URL + game?.coverMedia[1]}
                      alt="Description"
                      className="block gameCard object-cover"
                    />
                  </div>

                  <div className="flex flex-col justify-between items-start !ml-0 pt-2 pr-[6px] pb-3 w-full h-full">
                    <div className="flex flex-col w-full h-auto">
                      <h2 className={`singlegamemodaltitle ${!game.isWebinar ? '!mb-3' : ''}`}>
                        {game?.title && (
                          <>
                            {game.title.split(' ').slice(0, -1).join(' ')}{' '}
                            <span className="highlight">{game.title.split(' ').slice(-1)}</span>
                          </>
                        )}
                      </h2>
                      {
                        !game.isWebinar && (<>
                          <div className="show-on-desktop flex flex-row justify-between items-center w-full">

                            <div className="flex items-center">
                              <img
                                src={virtual}
                                alt="Logo 1"
                                className="mr-1 gameLogo object-cover"
                              />
                              <span className="text-[13px] text-center sm:text-left self-center singlegamemodalitem">
                                <span className="font-bold">Format</span>: {formatGameType(game?.gameType)}
                              </span>
                            </div>


                            <div className="flex items-center">
                              <img
                                src={participant}
                                alt="Logo 2"
                                className="mr-1 gameLogo object-cover"
                              />
                              <span className="text-[13px] text-center sm:text-left self-center singlegamemodalitem">
                                <span className="font-bold">Duration</span>: {game?.duration} Mins
                              </span>
                            </div>


                            <div className="flex items-center">
                              <img
                                src={time}
                                alt="Logo 3"
                                className="mr-1 gameLogo object-cover"
                              />
                              <span className="text-[13px] text-center sm:text-left self-center singlegamemodalitem">
                                <span className="font-bold">HeadCount</span>: {game?.maxPlayers}
                              </span>
                            </div>
                          </div>
                          <div className="flex sm:flex-row flex-col sm:flex-wrap justify-between items-center mb-3 p-0 sm:p-0 show-on-mobile">
                            <div className="flex items-center mb-4 sm:mb-0 w-full sm:w-1/3 bgPink">
                              <img src={virtual} alt="Logo 1" className="mr-1 gameLogo object-cover" />
                              <span className="text-[13px] text-center sm:text-left self-center singlegamemodalitem">
                                <span className="font-bold">Format</span>: {formatGameType(game?.gameType)}
                              </span>
                            </div>
                            <div className="flex items-center mb-4 sm:mb-0 w-full sm:w-1/3 bgPink">
                              <img src={participant} alt="Logo 2" className="mr-1 gameLogo object-cover" />
                              <span className="text-[13px] text-center sm:text-left self-center singlegamemodalitem">
                                <span className="font-bold">Duration</span>: {game?.duration} Mins
                              </span>
                            </div>
                            <div className="flex items-center w-full sm:w-1/3 bgPink">
                              <img src={time} alt="Logo 3" className="mr-1 gameLogo object-cover" />
                              <span className="text-[13px] text-center sm:text-left self-center singlegamemodalitem">
                                <span className="font-bold">HeadCount</span>: {game?.maxPlayers}
                              </span>
                            </div>
                          </div></>)
                      }


                      <div className="flex flex-col flex-grow items-start mt-[5px]">
                        <div className="w-full max-w-[680px]">
                          <p
                            className={`singlemodalPara mt-8 max-w-full ${expandedCardIndex === index ? "" : "max-h-[200px] overflow-y-auto"
                              } inline`}
                          >
                            {expandedCardIndex === index
                              ? game?.description
                              : game?.description.length > 320
                                ? `${game?.description.substring(0, 320)}...`
                                : game?.description}
                          </p>
                          {game?.description.length > 320 && (
                            <button
                              onClick={() => toggleDescription(index)}
                              className="mt-0 !text-[13px] showMoreBtn"
                            >
                              {expandedCardIndex === index ? "Show Less" : "Show More"}
                            </button>
                          )}
                        </div>
                      </div>

                    </div>
                    <div className="flex-shrink-0 mt-2 mt-5 text-left">
                      {game.isWebinar ? (
                        <Link
                          onClick={() => openWebinarModal(game)}
                          className="hover:opacity-80 rounded w-[155px] text-white transition-opacity duration-300 btn btn-primary"
                        >
                          View
                        </Link>
                      ) : (
                        <Link
                          to={`/game-detail/${game?.id}/${game?.title.replace(/ /g, '-')}`}
                          className="hover:opacity-80 rounded w-[155px] text-white transition-opacity duration-300 btn btn-primary"
                        >
                          Play Now
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div class="sm:block circle-shadow-bottom z-0 hidden"></div>
        </div>
      </div>
      <Footer {...props} isAdmin={isAdmin} />
    </div>
  );

}

export default OccasionGames
